import React from 'react';

const CrossIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#484747"
        stroke="#484747"
        d="M12.68 3.055h0L8.434 7.298s0 0 0 0a.615.615 0 01-.87 0h0l.353-.353-.354.353L3.32 3.055h0a.188.188 0 00-.266.265s0 0 0 0L7.3 7.565l5.38-4.51zm0 0a.188.188 0 01.265.265s0 0 0 0L8.701 7.565l3.979-4.51zm-9.36 9.89h0L7.564 8.7 3.32 12.945zm0 0a.188.188 0 01-.266-.265L7.3 8.435l-3.98 4.51zm9.36 0L8.434 8.7l.266-.265 4.244 4.245s0 0 0 0a.188.188 0 01-.266.265h0zm-4.113-4.71c.03.075.076.143.133.2v-.87a.615.615 0 00-.133.67zm-1.135-.47a.615.615 0 00-.133-.2v.87a.615.615 0 00.133-.67z"
      />
    </svg>
  );
};

export default CrossIcon;
