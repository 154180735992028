import React from 'react';

const SuccessIcon = () => {
  return Icons[Math.floor(Math.random() * Icons.length)]();
};

const Icons = [
  // Icon: General-succes - 1
  () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="375" height="360" fill="none" viewBox="0 0 375 360">
        <g clipPath="url(#clip0_6133_15205)">
          <path
            fill="#CADFEA"
            d="M177.91 264.67l-21.68-47.08 18.79-11.31a120.076 120.076 0 0137.61-14.7l34.42-7.11 58.94 8.96c8.54 1.3 16.55 4.84 23.23 10.23.86-6.73 1.26-13.62 1.15-20.61-1.24-78.61-66.72-141.8-145.33-140.37C107.46 44.09 45 107.43 45 185.35c0 77.92 63.89 142.7 142.7 142.7 5.59 0 11.11-.33 16.54-.96L208.2 250l-30.29 14.67z"
          ></path>
          <path
            fill="#F4A300"
            d="M329.23 203.66A48.59 48.59 0 00306 193.43l-58.94-8.96-36.09 7.45a109.932 109.932 0 00-34.48 13.48l-20.26 12.19 21.68 47.08L208.2 250l-3.96 77.09c65-7.5 116.7-58.67 124.99-123.43z"
          ></path>
          <path
            fill="#B45C49"
            d="M140.08 275.18l35.66-15.22-16.69-36.25-33.87 17.41 14.9 34.06zM88.14 166.99c-21.93-16.69-18.6-38.12-16.46-45.25 2.14-7.13 14.13-16.92 18.21-17.57 4.08-.65 4.85 5.24 5.13 8.04.28 2.8 2.08 18.05 2.08 18.05s3.14-13.34 17.78-13.28c10.23.04 15.61 8.94 15.86 14.16.11 2.25-1.72 3.05-2.9 3.42-1.1.35-2.23.03-3.23-1.16-2.55-3.04-5.25-9.39-11.88-7.18-6.63 2.21-7.8 10.41-5.78 16.48 2.55 7.64 15 11.23 19.4-3.26.26-.84 1.39-2.11 2.19-2.48.8-.37 3.78.54 4.48 1.88 2.14 4.12-1.91 12.85-6.04 16.3-4.73 3.94-9.62 6.35-15.66 5.17"
          ></path>
          <path
            fill="#B45C49"
            d="M88.14 166.99l20.78 90.87c.72 3.49 2.11 6.84 4.22 9.72 1.08 1.47 4.21 3.94 8.07 6.68 10.67 7.58 25.58 1.23 27.49-11.72 1-6.78 1.39-13.02.2-16l-37.57-86.24"
          ></path>
          <path
            fill="#281713"
            d="M276.62 106.1l-3.11 19.37-8 7.04-12.7-9.94 10.37-21.45 13.44 4.98zM272.98 144.68s2.87 12.16-6.82 13.83c-9.69 1.67.7-16.84.7-16.84l6.12 3.01z"
          ></path>
          <path
            fill="#B45C49"
            d="M267.28 162l-35.45 14.22c-8.37 2.98-13.26.31-15.88-1.6-5.28-3.84-7.52-11.67-7.52-11.67l-7.21-44.09s-7.36-22.26 33.14-25.93c0 0 24.33-19.51 32.08 4.64l.03 40.78.81 23.65z"
          ></path>
          <path fill="#B45C49" d="M267.66 144.65l.04 18.17-10.14-9.56-1.64-14.42 11.74 5.81z"></path>
          <path
            fill="#B45C49"
            d="M231.52 132.04l-.32 52.48-.02 5.28.88 1.38c7.6 11.85 24.74 12.35 33.01.96l1.96-2.69 1.59-58.4-37.11 1 .01-.01z"
          ></path>
          <path
            fill="#B45C49"
            d="M271.68 145.8c5.661 0 10.25-4.589 10.25-10.25s-4.589-10.25-10.25-10.25-10.25 4.589-10.25 10.25 4.589 10.25 10.25 10.25z"
          ></path>
          <path fill="#fff" d="M254.39 146.56s-2.28 15.08-25.9 16.8l-2.33-10.61 28.22-6.19h.01z"></path>
          <path fill="#A24538" d="M253.1 168.07l-21.92 19.87.15-11.55 21.77-8.32z"></path>
          <path
            fill="#B45C49"
            d="M136.242 276.839c10.132-3.666 15.286-15.096 11.511-25.529-3.774-10.434-15.048-15.92-25.181-12.254-10.132 3.666-15.286 15.095-11.511 25.529 3.775 10.433 15.049 15.92 25.181 12.254z"
          ></path>
          <path
            fill="#757375"
            d="M265.78 92.95l-30.17 28.53c-2.13 2.63-5.31 3.85-8.44 3.24-8.36-1.62-25.54-4.29-38-1.73-3.39.7-6.44 1.46-9.15 2.23-2.49.71-3.98-2.99-1.86-4.61l20.79-21.19s50.29-17.84 66.83-6.46v-.01z"
          ></path>
          <path
            fill="#9E9999"
            d="M263.17 103.63l13.46 2.47s4.33-42.7-40.65-42.7c-34.54 0-37.02 36.01-37.02 36.01s4.6-1.53 21.23-1.63c16.63-.1 29.38 3.36 42.98 5.85z"
          ></path>
          <path
            fill="#757375"
            d="M222.44 92.41a9.5 9.5 0 009.5-9.5 9.5 9.5 0 00-9.5-9.5 9.5 9.5 0 00-9.5 9.5 9.5 9.5 0 009.5 9.5z"
          ></path>
          <path
            fill="#80B076"
            d="M115.58 41.32c-16.25 13.92-18.13 38.38-4.21 54.63 13.92 16.25 38.38 18.13 54.63 4.21.59-.51 1.16-1.04 1.72-1.57l13.3 2.41c1.08.2 1.93-.92 1.46-1.91l-6.15-12.91c5.71-13.17 3.85-29.02-6.12-40.65-13.92-16.25-38.38-18.13-54.63-4.21z"
          ></path>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="7"
            d="M125.17 71.45l13.04 15.43 18.55-32.18"
          ></path>
          <path
            fill="#A24538"
            d="M142.21 232.36l6.8 14.52-.99-17.5-5.81 2.98zM111.32 160.3s-5.06-.41-10.35-2.39c0 0 2.62 6.82 12.06 6.36l-1.71-3.97z"
          ></path>
          <path
            fill="#B45C49"
            d="M127.25 134.61a3.48 3.48 0 100-6.96 3.48 3.48 0 000 6.96zM129.78 143.57a3.48 3.48 0 100-6.96 3.48 3.48 0 000 6.96z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_6133_15205">
            <path fill="#fff" d="M0 0H285.4V296.05H0z" transform="translate(45 32)"></path>
          </clipPath>
        </defs>
      </svg>
    );
  },
  // Icon: General-succes - 2
  () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="375" height="360" fill="none" viewBox="0 0 375 360">
        <g clipPath="url(#clip0_6133_15206)">
          <path
            fill="#CADFEA"
            d="M235.19 234.38l6.28 88.6a142.549 142.549 0 0040.25-24.88l-37.51-50.53-9.02-13.19zM117.05 314.77c7.9 4.51 16.27 8.29 25.02 11.24l-.76-10.9c-9.23 1.2-17.27.92-24.26-.34z"
          ></path>
          <path
            fill="#CADFEA"
            d="M73.74 244.62c4.79-18.8 15.84-31.37 15.84-31.37 4.61-6.85 20.9-22.46 23.21-35.43 2.73-15.3-3.62-28.53-3.62-28.53-4.64-10.89-7.9-22.68-3.4-33.75 8.04-19.81 29.26-18.4 29.26-18.4C149.3 36.48 206.4 53 206.4 53c39.25 11.37 44.8 47.31 44.8 47.31 5.15 38.06-10.71 62.53-10.71 62.53l-7.53 19.54s-.26 2.69-.74 7.17c12.01 1.56 22.69 8.46 29.01 18.82l33.8 55.41 12.48-50.88c6.66 2.2 13.32 4.41 19.98 6.58 1.9-9.28 2.89-18.89 2.89-28.73 0-78.81-63.89-142.7-142.7-142.7C108.87 48.05 45 111.96 45 190.77c0 9.63.96 19.04 2.78 28.14 6.07-1.98 12.13-3.98 18.19-5.99l7.77 31.7z"
          ></path>
          <path
            fill="#6E2F4C"
            d="M141.31 315.11l-5.48-78.35-6.56 10.81-36.75 49.51c7.5 6.72 15.72 12.67 24.53 17.7 6.99 1.26 15.03 1.53 24.26.34v-.01zM240.49 162.85s15.86-24.47 10.71-62.53c0 0-5.55-35.94-44.8-47.31 0 0-57.1-16.53-71.37 44.14 0 0-21.22-1.42-29.26 18.4-4.49 11.07-1.24 22.85 3.4 33.75 0 0 6.35 13.23 3.62 28.53-2.31 12.96-18.6 28.58-23.21 35.43 0 0-11.06 12.58-15.84 31.37l4.7 19.18 31.04-51.69a40.021 40.021 0 0126.23-18.59l28.65-5.89a69.967 69.967 0 0128.26-2.58l38.98 4.43c.21.02.41.06.62.09.47-4.49.74-7.17.74-7.17l7.53-19.54v-.02z"
          ></path>
          <path
            fill="#4FA3D9"
            d="M74.84 281.54a62.03 62.03 0 01-1.56-5.48c-12.41-16.63-21.28-36.05-25.5-57.15-.99.32-1.97.65-2.96.97l12.49 71.26s4.16 17.26 14 18.53c0 0 7.66 3.91 17.83-8.19-10.6-9.4-14.3-19.94-14.3-19.94zM281.72 298.1l.25.33c11.33 15.78 20.2 11.24 20.2 11.24 9.85-1.27 14-18.53 14-18.53l12.49-71.26c-.39-.13-.77-.25-1.16-.38-6.35 31.07-22.81 58.47-45.78 78.61v-.01z"
          ></path>
          <path
            fill="#4FA3D9"
            d="M327.5 219.5c-6.67-2.17-13.33-4.37-19.98-6.58l-12.48 50.88-33.8-55.41a39.972 39.972 0 00-29.01-18.82c-2.38 22.55-10.11 90.89-16.89 95.97-30.1 22.58-73.76 29.54-73.76 29.54l-.27.03.76 10.9c14.33 4.83 29.67 7.45 45.63 7.45 19.02 0 37.17-3.73 53.77-10.49l4.45-48.05c.76-8.24-.53-16.54-3.77-24.16l-6.96-16.39 9.02 13.19 37.51 50.53c22.97-20.14 39.43-47.53 45.78-78.61v.02zM73.74 244.62l-7.77-31.7c-6.06 2.01-12.12 4.01-18.19 5.99 4.22 21.1 13.09 40.53 25.5 57.15-2.73-11.48-1.89-22.21.46-31.44zM92.52 297.08a144.16 144.16 0 01-19.24-21.02c.43 1.81.95 3.64 1.56 5.48 0 0 3.7 10.54 14.3 19.94.78-.92 1.57-1.93 2.37-3.05l1.01-1.36v.01z"
          ></path>
          <path
            fill="#4FA3D9"
            d="M192.62 185.05c-9.5-1.08-19.11-.2-28.26 2.58l-28.65 5.89c-10.96 2.25-20.47 9-26.23 18.59L78.44 263.8l-4.7-19.18c-2.35 9.23-3.19 19.96-.46 31.44a143.6 143.6 0 0019.24 21.02l36.75-49.51 6.56-10.81 5.48 78.35.27-.03s43.66-6.95 73.76-29.54c6.78-5.08 14.51-73.42 16.89-95.97-.21-.03-.41-.07-.62-.09l-38.98-4.43h-.01z"
          ></path>
          <path
            fill="#EC9880"
            d="M48.32 193.38s7.43-8.4 4.28-29.53l-2.9-23.79s-1.04-6.98-7.29-6.21c0 0-5.19 1.12-4.3 8.43l3.18 26.11s3.74 18.66 7.03 24.99z"
          ></path>
          <path
            fill="#EC9880"
            d="M52.09 158.56l14.3-4.78s11.87-5.44 13.11 8.15c0 0 6.08 37.48-23.62 43.6l-11.63-4.64s-1.42-12.05-3.53-39.15l11.36-3.18h.01z"
          ></path>
          <path
            fill="#EC9880"
            d="M66.29 214.26l-3.06-12.46-21.83-1.41 3.44 19.62c7.1-2.09 14.27-3.95 21.45-5.75z"
          ></path>
          <path
            fill="#EC9880"
            d="M41.53 152.71s-4.75 21.3-.13 47.68l9.54 1.72M326.16 193.98s-7.2-8.14-4.15-28.63l2.81-23.07s1.01-6.76 7.07-6.03c0 0 5.03 1.09 4.17 8.18l-3.09 25.32s-3.63 18.09-6.82 24.23h.01z"
          ></path>
          <path
            fill="#EC9880"
            d="M322.5 160.22l-13.86-4.63s-11.51-5.28-12.72 7.9c0 0-5.9 36.34 22.9 42.28l11.28-4.5s1.38-11.68 3.42-37.96l-11.02-3.09z"
          ></path>
          <path
            fill="#EC9880"
            d="M328.63 220.05l3.45-19.66-21.83 1.41-3.09 12.61c7.32 1.29 14.42 3.4 21.48 5.65l-.01-.01z"
          ></path>
          <path fill="#EC9880" d="M332.74 154.54s3.82 20.27-.67 45.85l-8.46 2.05"></path>
          <path
            fill="#80B076"
            d="M317.8 32.8c18.2 11.26 23.83 35.13 12.57 53.33-11.26 18.2-35.13 23.83-53.33 12.57-.67-.41-1.31-.85-1.94-1.29l-12.77 4.43c-1.04.36-2.05-.61-1.74-1.66l4.09-13.7c-7.67-12.14-8.27-28.08-.21-41.11 11.26-18.2 35.13-23.83 53.33-12.57z"
          ></path>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="7"
            d="M281.23 66.85l13.05 15.43 18.54-32.18"
          ></path>
          <path
            fill="#EC9880"
            d="M161.1 145.83l2.4 44.94c8.04 6.39 23.92 4.51 31.67-2.23l-3.09-44.61-30.98 1.9zM150.32 156.58c5.628 0 10.19-4.562 10.19-10.19 0-5.628-4.562-10.19-10.19-10.19-5.628 0-10.19 4.562-10.19 10.19 0 5.628 4.562 10.19 10.19 10.19z"
          ></path>
          <path
            fill="#EC9880"
            d="M167.67 166.45l37.97-8.21c8.57-2.12 11.16-7.01 12.28-10.03 2.27-6.08-.17-13.79-.17-13.79l-8.53-30.25s-15.76-32.74-51.34-13.58c0 0-12.32 2.74-8.55 35.92l6.84 19.33 11.5 20.6v.01z"
          ></path>
          <path fill="#fff" d="M167.67 138.97s5.69 15.15 26.38 10.38l-.63-11.76-25.75 1.38z"></path>
          <path fill="#EC9880" d="M156.41 152.35l5.81 10.17 7.28-8.57-6.55-12.85-6.54 11.25z"></path>
          <path fill="#DB816D" d="M172.97 165.12l20.28-4.19.58 8.38-20.86-4.19z"></path>
        </g>
        <defs>
          <clipPath id="clip0_6133_15206">
            <path fill="#fff" d="M0 0H298.16V306.47H0z" transform="translate(38 27)"></path>
          </clipPath>
        </defs>
      </svg>
    );
  },
  // Icon: General-succes - 3
  () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="375" height="360" fill="none" viewBox="0 0 375 360">
        <g clipPath="url(#clip0_6133_15207)">
          <path
            fill="#CADFEA"
            d="M329.48 187.06c0-78.81-63.89-142.7-142.7-142.7-78.81 0-142.7 63.89-142.7 142.7 0 34.08 11.96 65.37 31.89 89.91 4.64-26.57 10.73-52.89 10.73-52.89l127.27 18.25c9.01 13.02 4.76 27.53 4.76 27.53 17.35 15.34 17.38 39.58 16.12 51.59 55.16-19.73 94.63-72.45 94.63-134.39z"
          ></path>
          <path
            fill="#4FA3D9"
            d="M218.73 269.86s4.26-14.52-4.76-27.53L86.7 224.08s-6.09 26.32-10.73 52.89c26.16 32.21 66.08 52.79 110.81 52.79 16.87 0 33.05-2.94 48.07-8.31 1.25-12.01 1.22-36.25-16.12-51.59z"
          ></path>
          <path
            fill="#4FA3D9"
            d="M211.98 191.82s-29.48-8.28-57.36-8.11c-27.88.17-55.51 11.02-55.51 11.02l-12.4 31.22 12.4 38.84h105.34l9.52-21.43.63-35.51-2.62-16.03z"
          ></path>
          <path
            fill="#EC9880"
            d="M140.52 177.29l32.79.8c7.46-.04 10.55-3.54 12.07-5.8 3.07-4.54 2.6-11.35 2.6-11.35l-2.05-40.27s-5.4-16.25-38.41-7.58c0 0-24.77-8.45-23.98 12.82l11.62 32.19 5.35 19.19h.01z"
          ></path>
          <path
            fill="#F0F0F0"
            d="M184.51 186.12c-3.56 25.46-27.82 35.29-27.82 35.29-35.62-11.42-37.14-32.92-37.14-32.92s14.32-4.32 32.8-4.59c18.48-.27 32.15 2.22 32.15 2.22h.01z"
          ></path>
          <path
            fill="#EC9880"
            d="M171.78 191.62l-3.92-35.48-36.6-2.56-.55 38.67c-.03 2.03.57 4.02 1.72 5.7 6.38 9.34 25.34 13.2 33.64 5.07 3.1-3.04 6.35-7.11 5.71-11.41v.01z"
          ></path>
          <path
            fill="#4FA3D9"
            d="M213.97 243.36l46.44-92.46c5.45-12.14 5.82-28.17 5.82-28.17l5.28-37.61-26.56-3.08-6.49 47.16-51.68 57.86M117.45 189.02l-49.03-52.41-10.87-47.15-25.3 5.67 8.36 37.37s1.65 15.72 7.99 27.2l36.23 68.9"
          ></path>
          <path
            fill="#EC9880"
            d="M50.91 80.72l3.3-25.85s.51-3.82-3.4-4.75c0 0-3.64-.74-4.61 4.02l-1.39 11.85-7.76-24.16a2.28 2.28 0 00-2.84-1.49c-7.06 2.19-27.36 11.7-1.31 44.48l18.02-4.1h-.01zM250.61 72.76l-5.55-26.17s-.84-3.87 3.08-5.14c0 0 3.67-1.07 5.05 3.72l2.42 12.01 5.9-25.36a2.337 2.337 0 012.78-1.76c7.41 1.64 28.97 9.67 5.08 45.39l-18.77-2.68.01-.01z"
          ></path>
          <path
            fill="#F0F0F0"
            d="M173.18 147.71s-18.74-.24-18.58 11.32c0 0 8.43 3.77 19.83 3.68 11.4-.08 18.35-5.02 18.35-5.02s2.42-11.23-19.6-9.98zM120.5 130.73c.7 11.78 4.98 19.51 4.98 19.51l9.84 2.95-.63-2.69 7.17-1.73-5.82-19.37s-16.24-10.45-15.54 1.33z"
          ></path>
          <path
            fill="#EC9880"
            d="M130.26 165.98a8.6 8.6 0 008.6-8.6 8.6 8.6 0 00-8.6-8.6 8.6 8.6 0 00-8.6 8.6 8.6 8.6 0 008.6 8.6z"
          ></path>
          <path fill="#DB816D" d="M170.29 178.08l-22.97-3.94 23.6 9.71-.63-5.77z"></path>
          <path
            fill="#F0F0F0"
            d="M29.85 85.25l25.27-5.54 2.44 9.75-25.72 5.76-1.99-9.97zM246.37 71.83l26.06 3.14-.92 10.16-26.56-3.08 1.42-10.22z"
          ></path>
          <path
            stroke="#4C3938"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M158.54 164.13c3.57 1.19 9.87 2.66 18.72 2.32"
          ></path>
          <path
            fill="#6E2F4C"
            d="M209.35 107.42l-25.02-5.74-.33-.08-2.38-6.75-6.36-18.03s-9.81-7.1-32.29-2.76c-22.48 4.34-27.36 14.19-27.36 14.19l1.91 21.4.74 8.29-18.3 9.41c-1.56.78-1.62 3-.08 3.82 5.53 2.98 20.21 7.17 55.98 1.27 37.54-6.2 50.48-16.56 54.67-21.51 1.05-1.24.41-3.14-1.17-3.5l-.01-.01z"
          ></path>
          <path
            fill="#80B076"
            d="M354.26 159.69c5.45 20.69-6.91 41.88-27.6 47.33-20.69 5.45-41.88-6.91-47.33-27.6a37.1 37.1 0 01-.52-2.27l-12.32-5.58c-1-.45-1.07-1.85-.11-2.4l12.4-7.12c2.79-14.08 13.33-26.06 28.15-29.96 20.69-5.45 41.88 6.91 47.33 27.6z"
          ></path>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="7"
            d="M300.89 168.74l13.04 15.44L332.48 152"
          ></path>
          <path
            fill="#8C4167"
            d="M118.25 117.94s14.5 1.26 36.64-3.41 29.1-12.93 29.1-12.93l-2.75-7.79s-16.68 8.57-28.72 11.28c-12.04 2.71-35.08 3.87-35.08 3.87l.8 8.97.01.01z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_6133_15207">
            <path fill="#fff" d="M0 0H335.54V299.76H0z" transform="translate(20 30)"></path>
          </clipPath>
        </defs>
      </svg>
    );
  },
];

export default SuccessIcon;
