import React from 'react';

const UpdateIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_361_515"
        style={{ maskType: 'alpha' }}
        width="16"
        height="22"
        x="4"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          d="M4.45 14.725A6.22 6.22 0 014.1 13.4a9.2 9.2 0 01-.1-1.35c0-2.183.787-4.062 2.362-5.637C7.938 4.838 9.817 4.05 12 4.05h1.075L11.55 2.525a.623.623 0 01-.188-.487.71.71 0 01.213-.488.663.663 0 01.488-.2c.191 0 .354.067.487.2l2.7 2.725a.762.762 0 01.175.25c.033.083.05.175.05.275 0 .1-.017.192-.05.275a.762.762 0 01-.175.25l-2.7 2.7c-.133.133-.3.2-.5.2a.678.678 0 01-.5-.2.679.679 0 01-.2-.5c0-.2.067-.367.2-.5l1.475-1.475H12c-1.783 0-3.313.638-4.588 1.913C6.138 8.738 5.5 10.267 5.5 12.05c0 .4.033.783.1 1.15.067.367.15.717.25 1.05a.715.715 0 010 .375.682.682 0 01-.175.325c-.217.217-.45.304-.7.263-.25-.042-.425-.205-.525-.488zm6.975 7.775L8.7 19.8a.762.762 0 01-.175-.25.734.734 0 01-.05-.275c0-.1.017-.192.05-.275a.762.762 0 01.175-.25l2.725-2.725a.663.663 0 01.487-.2c.192 0 .355.067.488.2s.2.296.2.488a.663.663 0 01-.2.487l-1.525 1.525H12c1.783 0 3.313-.637 4.587-1.912 1.276-1.275 1.913-2.805 1.913-4.588 0-.4-.03-.787-.087-1.162A5.997 5.997 0 0018.15 9.8a.76.76 0 01-.012-.362.596.596 0 01.162-.313c.217-.217.45-.304.7-.262.25.041.425.204.525.487.167.433.288.875.363 1.325.074.45.112.9.112 1.35 0 2.183-.788 4.062-2.363 5.638-1.575 1.575-3.454 2.362-5.637 2.362h-1.125L12.4 21.55c.133.133.2.292.2.475a.649.649 0 01-.2.475.663.663 0 01-.488.2.663.663 0 01-.487-.2z"
        ></path>
      </mask>
      <g mask="url(#mask0_361_515)">
        <path fill="#484747" d="M0 0H24V24H0z"></path>
      </g>
    </svg>
  );
};

export default UpdateIcon;
